@if ( !showOutlet() ) {
<div class="h-screen flex flex-column justify-content-center align-items-center color-primary">
  <h1 class="text-center">Dispositivo no válido</h1>
  <p>Por favor haga el proceso desde un dispositivo móvil.</p>
</div>
} @else {
<router-outlet />
}

<app-spinner />
<app-error-modal />
