import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, isDevMode  } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmationService, MessageService } from 'primeng/api';

import { HttpLoaderFactory, localStorageSyncReducer } from '@core/helpers';
import { addTokenAndPhoneInBodyInterceptor, spinnerInterceptor, updateRequestBodyInterceptor } from '@core/interceptors';
import { ROOT_REDUCER } from '@store/app.state';
import { routes } from './app.routes';
import { AddressAttributesEffects, ClientAttributesEffects, GlobalAttibutesEffects, resetStateMetaReducer } from './store';



export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding()),
    importProvidersFrom(TranslateModule.forRoot({
      defaultLanguage: 'es',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      }
    }), NgxUiLoaderModule, JwtModule.forRoot({
      config: {
        tokenGetter: function tokenGetter() {
          return sessionStorage.getItem('tokenTelcel');
        },
        allowedDomains: ['localhost:4200', 'localhost:8080'],
        disallowedRoutes: ['']
      }
    })),
    provideAnimations(),
    provideHttpClient(
      withInterceptors([
        addTokenAndPhoneInBodyInterceptor,
        updateRequestBodyInterceptor,
        spinnerInterceptor,
      ])
    ),
    provideStore(ROOT_REDUCER, {
      metaReducers: [localStorageSyncReducer, resetStateMetaReducer]
    }),
    provideStoreDevtools({ maxAge: 25, logOnly: !isDevMode() }),
    provideEffects([GlobalAttibutesEffects, ClientAttributesEffects, AddressAttributesEffects]),
    NgxUiLoaderService,
    ConfirmationService,
    MessageService,
  ]
}
