import { HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { ServicesNames } from '@core/enums';
import { Store } from '@ngrx/store';

import { selectClientPhone, selectToken } from '@store/index';

// export const addTokenAndPhoneInBodyInterceptor: HttpInterceptorFn = (req, next) => next(req)

export const addTokenAndPhoneInBodyInterceptor: HttpInterceptorFn = (req, next) => {
  const store = inject(Store);

  if (req.method === 'GET' || notUpdateBody(req)) return next(req);


  const token = store.selectSignal(selectToken);
  const phone = store.selectSignal(selectClientPhone);

  const getBody = req.body ? { ...req.body } : {};

  const body = {
    ...getBody,
    numeroCelular: phone(),
    token: token()
  };

  return next(req.clone({
    body: body
  }));
};

function notUpdateBody(request: HttpRequest<unknown>): boolean {
  const filteredRequests = [
    ServicesNames.LOGIN,
    ServicesNames.VALIDAR_DATOS_URL,
    ServicesNames.REGISTRAR_CLIENTE,
    ServicesNames.VALIDAR_OTP_SMS,
    ServicesNames.GET_ACCESS_TOKEN_VERIDAS,
    ServicesNames.VALIDAR_OTP_EMAIL,
    ServicesNames.SED_VALIDA_OCR,
    ServicesNames.SEND_DATOS_CLIENTE
  ];

  return filteredRequests.some(serviceName => request.url.includes(serviceName));
}
