
import { ChangeDetectionStrategy, Component, inject, OnDestroy, computed, OnInit } from '@angular/core';
import { ActivatedRoute, Params, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { filter } from 'rxjs/operators';

import { ErrorModalComponent, SpinnerComponent } from '@shared/components';
import { enableComputerUse, selectDesktopUse } from './store';
import { Subscription } from 'rxjs';
/**
 * Componenten principal donde se aloja el router-outlet
 ** Creado por: Jesus Martínez Torres
 ** Fecha: 10/04/2023
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterOutlet,
    NgxUiLoaderModule,
    SpinnerComponent,
    ErrorModalComponent,
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  readonly #activatedRoute = inject(ActivatedRoute);
  readonly #store = inject(Store);
  readonly #deviceDetectorService = inject(DeviceDetectorService);

  #isMobile = this.#deviceDetectorService.isMobile();
  #allowDesktopUse = this.#store.selectSignal(selectDesktopUse);
  #subscrition: Subscription | null = null;

  showOutlet = computed(() => this.#isMobile || this.#allowDesktopUse());

  ngOnInit(): void {
    if (!this.showOutlet()) {
      this.getQueryParams();
    }
  }

  ngOnDestroy(): void {
    this.#subscrition?.unsubscribe();
  }


  private getQueryParams() {
    this.#subscrition = this.#activatedRoute.queryParams
      .pipe(
        filter(params => Object.keys(params).length > 0) // Solo pasa si hay parámetros
      )
      .subscribe((params: Params) => {
        if (params.hasOwnProperty('desktop')) {
          this.#store.dispatch(enableComputerUse({ computerUse: true }));
        } else {
          this.#store.dispatch(enableComputerUse({ computerUse: this.#allowDesktopUse() }))
        }
      })
  }
}
